.summary {
    font-size: calc(16rem / 16);
    line-height: calc(29/20);

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(20rem / 16);
    }
}
.summary__total {
    margin-top: calc(20rem / 16);
    padding-top: calc(20rem / 16);
    border-top: calc(1rem / 16) solid rgba(255,255,255,.3);

    @media screen and (min-width: 768px) {
        margin-top: calc(25rem / 16);
        padding-top: calc(25rem / 16);
    }
}
.summary__total__price {
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
    }
}
.summary__item + .summary__item {
    margin-top: calc(10rem / 16);
    padding-top: calc(20rem/16);
    border-top: calc(1rem/16) solid var(--color-dark-grey);
    @media screen and (min-width: 768px) {
        padding-top: calc(10rem/16);
    }
}

.summary__item--spinner {
    max-width: calc(112rem/16);
}

.summary.summary--sm {
    font-size: calc(14rem / 16);
    line-height: calc(29/20);

    @media screen and (min-width: 1200px) {
        font-size: calc(16rem / 16);
    }
}
