:root {
    --color-primary: #CD9C09;
    --color-secondary: #161615;
    --color-secondary-transparent: rgba(22, 22, 21, 0.85);
    --color-text-default: var(--color-default);
    --color-text-muted: #868E96;

    --color-default: #fff;
    --color-white: #FFF;
    --color-light-grey: #F6F2EF;
    --color-dark-grey: #CECECE;
    --color-dark: #2B2B2A;

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #fff;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-secondary);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #b38808;
    --color-secondary-dark: #0b0b0a;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #e2ac0a;
    --color-secondary-light: #2c2c2a;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "Josefin Sans", sans-serif;
    --font-default-weight: 400;
    --font-default-bold: "Josefin Sans Bold", sans-serif;
    --font-bold-weight: 700;
    --font-default-medium: "Josefin Sans Medium", sans-serif;
    --font-medium-weight: 600;

    --font-size-default: calc(18rem / 16);

    --navbar-brand-bg: #CD9C09;
    --brand-primary-color: var(--color-primary);
    --brand-primary-color-dark: var(--color-primary-dark);
    --color-hotels: #C94141;
    --color-resorts: #D8AA00;
    --color-waldhuus: #205B41;
    --color-mph: #CDB3A7;
}