.sticky-bottom-bar {
    transition: background 250ms ease-in-out, box-shadow 250ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;

    @media screen and (min-width: 768px) {
        padding: calc(5rem / 16) 0;
        height: calc(75rem / 16);
    }

    @media screen and (min-width: 1400px) {
        height: calc(100rem / 16);
    }
}
.is-affix.sticky-bottom-bar {
    background: #fff;
    color: var(--color-secondary);
    box-shadow: 0 0 calc(40rem / 16) 0 rgba(0,0,0,0.3);
}